<template>
  <section :class="$style.locations">
    <div :class="$style.buttonWrapper">
      <el-button
        type="primary"
        @click="$router.push('locations-delivery/create')"
      >
        Создать локацию
      </el-button>
    </div>
    <el-table :data="locations" stripe :class="$style.table">
      <el-table-column prop="name" label="Название"> </el-table-column>
      <el-table-column prop="lowerPrice" label="Нижняя граница">
        <template slot-scope="scope">
          <span> {{ scope.row.lowerPrice }} &#8381; </span>
        </template>
      </el-table-column>
      <el-table-column prop="higherPrice" label="Верхняя граница">
        <template slot-scope="scope">
          <span> {{ scope.row.higherPrice }} &#8381; </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isDefault"
        label="По умолчанию"
        width="150"
        sortable
      >
        <template slot-scope="scope">
          <el-checkbox :value="scope.row.isDefault"> </el-checkbox>
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="'locations-delivery/' + String(scope.row.id)"
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  components: {
    ActionButtons,
  },
  data() {
    return {
      locations: [],
      limit: 10,
      page: 1,
      total: 10,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.PaymentServiceCore.DeliveryPricesActions.getList({
          limit: this.limit,
          page: this.page,
        })
      if (error) return
      this.locations = value.data
      this.total = value.meta.count

      loading.close()
    },
    async remove(data) {
      const isConfirm = confirm(`Вы точно хотите удалить ${data.name} ?`)
      if (isConfirm) {
        const res =
          await delivery.PaymentServiceCore.DeliveryPricesActions.delete(
            data.id,
          )
        if (!res.error) {
          this.getList()
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.locations {
  padding: 1rem;
  position: relative;
  .buttonWrapper {
    @include stickyWrapper;
  }
  .pagination {
    @include pagination;
  }
}
</style>
